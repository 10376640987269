import React from 'react';
import Title from '../../components/Title';
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import Clause from './Clause';
import styles from './styles.module.sass';
import clauses from '../../data/privacy-policy';

export default ({
  seo,
  location,
  terms = clauses,
  url = '_url:privacy-policy',
}) => (
  <Layout url={url}>
    <SEO {...seo} location={location}/>
    <div className={styles.container}>
      <Title variant='h1' align='center'>
        {seo.title}
      </Title>
      {seo.updated
        ? <div style={{ textAlign: 'center', fontSize: '14px' }}>{seo.updated}</div>
        : null
      }
      {terms.map((c, key) => (
        <Clause key={key}
          number={c.number}
          title={c.title}
          content={c.content}/>
        )
      )}
    </div>
  </Layout>
);