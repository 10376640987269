import React from 'react';
import Typography from '../../components/Typography';
import styles from './styles.module.sass';

export default ({ title = '', number = '', content = [] }) => (
  <>
    <Typography variant={content.length ? 'h4' : 'h3'}
        align='left'
        color='dark'
        className={styles.clauseTitleContainer}>
        {(number ? (number + ' ') : '')
        + title}
    </Typography>
    {content.map(c => (
      <Typography variant='subtitle2' 
        align='left'
        color='main'
        weight='light' 
        className={styles.clauseDescriptionContainer}>
        {c.number
        ? <b>{c.number}&nbsp;&nbsp;</b>
        : null}
        {c.title
        ? <>{c.title}<br /></>
        : null}
        {c.text}
      </Typography>
    ))}
  </>
);